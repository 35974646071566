<template>
  <v-card>
    <v-container>
      <v-sheet v-if="!constanciasGeneradas" max-width="400" elevation="12" rounded="lg"
        class="px-5 pb-5 pt-2 text-center mb-10 mx-auto">
        <v-icon color="secondary" icon="mdi-check" size="112">mdi-certificate</v-icon>
        <div>
          <v-btn color="primary" small @click="dialogFecha = true" :loading="loading">Generar constancias</v-btn>
        </div>
      </v-sheet>
      <v-sheet v-if="constanciasGeneradas && !constanciasEnviadas" elevation="12" max-width="400" rounded="lg"
        class="px-5 pb-5 text-center mx-auto mb-10">
        <v-icon color="secondary" icon="mdi-check" size="112">mdi-email</v-icon>
        <div>
          <v-btn color="primary" small @click="enviarCorreos()" :loading="enviando">Enviar correo con constancias</v-btn>
        </div>
      </v-sheet>

      <v-row v-if="enviando" class="py-10" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12"> ENVIANDO CORREOS CON CONSTANCIAS A LOS CONSEJEROS </v-col>
        <v-col cols="6">
          <v-progress-linear v-if="enviando" color="primary" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-if="!enviando && constanciasEnviadas">
        <v-container>
          <v-sheet elevation="12" max-width="600" rounded="lg" width="100%" class="text-center mx-auto px-7 pb-7 mb-10">
            <v-icon class="" color="secondary" icon="mdi-check" size="112">mdi-check</v-icon>

            <h2 class="text-h5 pb-5">Ya se han enviado las constancias a los consejeros</h2>


            Para más detalles, favor de contactar al administrador del correo: atencion.secretaria@administrativos.udg.mx

            <v-divider></v-divider>
          </v-sheet>
        </v-container>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogFecha" persistent width="auto">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Fecha de constancias</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
        </v-card-text>
        <v-card-text>
          <v-row>
          <v-col cols="12">
              <v-alert type="warning"  dense text>Una vez guardada no se podrá modificar</v-alert>
              <span>Selecciona la fecha de emisión para las constancias</span>
  
          </v-col>
          <v-col cols="12">
              <v-menu
              v-model="fechaMenu"
              :close-on-content-click="false"
              :nudge-right="100"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fechaTexto"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha"
                locale="es"
                @input="fechaMenu = false"
                :min="min"
              ></v-date-picker>
            </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters justify="end" style="gap: 5px;">
            <v-btn small color="primary" @click="constancias()">Guardar</v-btn>
            <v-btn small outlined @click="cerrar()">Cerrar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { generarConstancias, enviarConstancias } from './constancias.service.js'
export default {
  name: "constancias",
  props: ['constanciasGeneradas', 'enviando', 'constanciasEnviadas'],
  computed: {
    ...mapGetters(["httpURL"]),
     fechaTexto() {
      let fecha = DateTime.fromISO(this.fecha);
      return fecha.toLocaleString(DateTime.DATE_MED);
    },
  },
  data() {
    return {
      loading: false,
      loadingCorreos: false,
      correosEnviados: [],
      fecha: DateTime.now().toISODate(),
      min: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      fechaMenu: false,
      dialogFecha:false
    };
  },
  mounted() {
    this.$socket.on('conectado', mensaje => {
      console.log("Entro al socket")
      console.log(mensaje);
    })
    // Escucha el evento 'message' desde el servidor
    this.$socket.on('enviandoCorreos', env => {
      this.$emit('enviando', env)
    })

    this.$socket.on('correoEnviado', mensaje => {
      this.correosEnviados.push(mensaje)
    })

  },
  sockets: {
  },
  methods: {
    async enviarCorreos() {
      try {
        this.$emit('enviando', true)
        const serverResponse = await enviarConstancias(this.$route.params.idConsejo);
        this.$emit('enviando', false)
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        this.$emit('constanciasEnviadas', serverResponse.constanciasEnviadas)
      } catch (error) {
        this.$emit('enviando', false)
        this.$notificarError(error);
      }
    },
    async constancias() {
      try {
        this.loading = true
        const serverResponse = await generarConstancias(this.$route.params.idConsejo,this.fecha);
        this.loading = false;
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        this.dialogFecha=false;
        this.$emit('constanciasGeneradas', serverResponse.data.generadas)

      } catch (error) {
        this.dialogFecha=false;
        this.loading = false;
        this.$notificarError(error);
      }
    },
    cerrar(){
      this.dialogFecha=false;
      this.fechaMenu=false;
      this.fecha= DateTime.now().toISODate()
    }
  }
};
</script>